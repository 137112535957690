// eslint-disable-next-line no-var
declare var acConfig: any;

let _acConfig: any;

try {
  _acConfig = acConfig;
} catch {
  _acConfig = {
    // for testing purpose
    frontendUrls: {},
    newFrontendUrls: {},
  };
}

export interface ExternalUrls {
  contactSupport: string;
}

export const GlobalAcConfigSEPInternalFrontendUrlKey = {
  CHANGELOG: 'changelog',
  CONFIGURATION: 'configuration',
  REACT_CONFIGURATION: 'reactConfiguration',
  CONFIGURATION_V2: 'configurationv2',
  LOCALHOST: 'localhost',
  HOUSEKEEPING: 'housekeeping',
  INTEGRATIONS: 'integrations',
  REPORTS: 'reports',
  RESERVATIONS: 'reservations',
  RATE_MANAGER: 'rateManager',
  ACCOUNTS_RECEIVABLE: 'accountsReceivable',
  MEETINGS_AND_EVENTS: 'meetingsAndEvents',
  TA_COMMISSIONS: 'taCommissions',
  TRAVEL_AGENT_COMMISSIONS: 'travelAgentCommissions',
  TASK_MANAGEMENT: 'taskManagement',
  WORKFLOWS: 'workflows',
  NOTIFICATIONS: 'notifications',
  PROFILES: 'profiles',
  CASHIERING: 'cashiering',
  INDIVIDUAL_RESERVATIONS: 'individualReservations',
  ITINERARY: 'itinerary',
  RESERVATIONS_GROUPS: 'reservationsGroups',
  ACTIVITY_RESERVATIONS: 'activityReservations',
  CENTRAL_RESERVATION_OFFICE: 'centralReservationOffice',
  ACTIVITY_CONFIGURATION: 'activityConfiguration',
  AVAILABILITY: 'availability',
  ALLOCATION_CALENDAR: 'allocationCalendar',
  DATA_LAKE: 'dataLake',
  RESOURCE_MANAGEMENT: 'resourceManagement',
} as const;

export type GlobalACConfigSEPInternalFrontendUrlKeyType =
  (typeof GlobalAcConfigSEPInternalFrontendUrlKey)[keyof typeof GlobalAcConfigSEPInternalFrontendUrlKey];
export type GlobalAcConfigSEPInternalFrontendUrls = Record<
  GlobalACConfigSEPInternalFrontendUrlKeyType,
  string
>;

export const GlobalAcConfigSEPExternalFrontendUrlKey = {
  HOUSEKEEPING_MOBILE: 'housekeepingMobile',
  USER_DOCUMENTATION: 'userDocumentation',
  GUEST_SELF_SERVICE: 'guestSelfService',
  WORLD_MAP: 'worldMap',
  REGISTRATION_CARD: 'registrationCard',
  SYSTEM_MAINTENANCE: 'systemMaintenance',
  TRANSLATIONS: 'translations',
} as const;

export type GlobalAcConfigSEPExternalFrontendUrlKeyType =
  (typeof GlobalAcConfigSEPExternalFrontendUrlKey)[keyof typeof GlobalAcConfigSEPExternalFrontendUrlKey];
export type GlobalAcConfigSEPExternalFrontendUrls = Record<
  GlobalAcConfigSEPExternalFrontendUrlKeyType,
  string
>;

type SEPUnknownFrontendUrls = Record<string, string>;

export const SEPInternalFrontendUrlKey = (({
  REACT_CONFIGURATION,
  TA_COMMISSIONS,
  ...rest
}) => rest)(GlobalAcConfigSEPInternalFrontendUrlKey);

export type SEPInternalFrontendUrlKeyType =
  (typeof SEPInternalFrontendUrlKey)[keyof typeof SEPInternalFrontendUrlKey];
export type SEPInternalFrontendUrls = Record<
  SEPInternalFrontendUrlKeyType,
  string
> &
  SEPUnknownFrontendUrls;

export type SEPExternalFrontendUrls = Record<
  GlobalAcConfigSEPExternalFrontendUrlKeyType,
  string
> &
  SEPUnknownFrontendUrls;

export const SEPFrontendUrlKey = {
  ...SEPInternalFrontendUrlKey,
  ...GlobalAcConfigSEPExternalFrontendUrlKey,
} as const;

export type SEPFrontendUrlKeyType =
  (typeof SEPFrontendUrlKey)[keyof typeof SEPFrontendUrlKey];
export type KnownFrontendUrls = Record<SEPFrontendUrlKeyType, string>;

export interface RegionConfiguration {
  code: string;
  domain: string;
  name: string;
  global: boolean;
  regionsGroupCode: string;
}

export interface RegionsGroupConfiguration {
  code: string;
  name: string;
}

export type GlobalAcConfig = Omit<
  Config,
  'newFrontendUrls' | 'frontendUrls' | 'externalUrls'
> & {
  frontendUrls: GlobalAcConfigSEPExternalFrontendUrls;
  newFrontendUrls: GlobalAcConfigSEPInternalFrontendUrls;
};

export interface Config {
  apiUrl: string;
  frontendUrls: SEPExternalFrontendUrls;
  newFrontendUrls: SEPInternalFrontendUrls;
  containerFrontendUrl: string;
  identityOauthUrl: string;
  regionsGroups: RegionsGroupConfiguration[];
  regions: RegionConfiguration[];
  environmentType: 'cm' | 'local' | 'dev' | 'test' | 'uat' | 'pre' | 'prod';
  externalUrls: ExternalUrls;
  /** For testing purpose only */
  __dev__?: Record<string, string>;
}

export const getNewFrontendUrls = (
  newFrontendUrls: Partial<GlobalAcConfigSEPInternalFrontendUrls>
): GlobalAcConfigSEPInternalFrontendUrls => {
  const newFrontendDefaultUrls: GlobalAcConfigSEPInternalFrontendUrls = {
    changelog: '/changelog',
    configuration: '/configuration',
    configurationv2: '/configurationv2',
    reactConfiguration: '/configurationv2',
    localhost: '/',
    housekeeping: '/housekeeping',
    integrations: '/integrations',
    reports: '/reports',
    reservations: '/reservations',
    rateManager: '/rate-manager',
    accountsReceivable: '/accounts-receivable',
    meetingsAndEvents: '/meetings-and-events',
    travelAgentCommissions: '/travel-agent-commissions',
    taCommissions: '/travel-agent-commissions',
    taskManagement: '/task-management',
    workflows: '/workflows',
    notifications: '/notifications',
    profiles: '/profiles',
    cashiering: '/cashiering',
    individualReservations: '/individual-reservations',
    itinerary: '/itinerary',
    reservationsGroups: '/reservations-groups',
    activityReservations: '/activity-reservations',
    centralReservationOffice: '/central-reservation-office',
    activityConfiguration: '/activity-configuration',
    availability: '/availability',
    allocationCalendar: '/allocation-calendar',
    dataLake: '/data-lake',
    resourceManagement: '/resource-management',
  };

  return {
    ...newFrontendDefaultUrls,
    ...newFrontendUrls,
  };
};

export const getFrontendURL = (
  frontendName:
    | GlobalAcConfigSEPExternalFrontendUrlKeyType
    | GlobalACConfigSEPInternalFrontendUrlKeyType,
  acConfigFrontendUrls:
    | GlobalAcConfigSEPExternalFrontendUrls
    | GlobalAcConfigSEPInternalFrontendUrls,
  basePath?: string,
  acConfigNewFrontendUrls?: GlobalAcConfigSEPInternalFrontendUrls,
  frontendAlternateName?: string
) =>
  acConfigFrontendUrls[frontendAlternateName ?? frontendName] ??
  (acConfigNewFrontendUrls?.[frontendName]
    ? `${basePath}${acConfigNewFrontendUrls?.[frontendName]}`
    : '');

export const getExternalUrls = (
  configuredExternalUrls: ExternalUrls
): ExternalUrls => ({
  ...configuredExternalUrls,
  // eslint-disable-next-line max-len
  contactSupport:
    configuredExternalUrls.contactSupport ??
    // eslint-disable-next-line max-len
    'https://docs.shijigroup.com/bundle/Shiji_Enterprise_Platform_Contact_Details/page/CONTACT_DETAILS/concepts/c_shiji_enterprise_platform_contact_support.html',
});

export const mapFrontendUrls = (
  acConfigFrontendUrls:
    | GlobalAcConfigSEPExternalFrontendUrls
    | GlobalAcConfigSEPInternalFrontendUrls,
  basePath?: string,
  acConfigNewFrontendUrls?: GlobalAcConfigSEPInternalFrontendUrls
): KnownFrontendUrls => ({
  ...acConfigFrontendUrls,
  localhost: getFrontendURL(
    'localhost',
    acConfigFrontendUrls,
    basePath,
    acConfigNewFrontendUrls
  ),
  configuration: getFrontendURL(
    'configuration',
    acConfigFrontendUrls,
    basePath,
    acConfigNewFrontendUrls
  ),
  configurationv2:
    getFrontendURL(
      'reactConfiguration',
      acConfigFrontendUrls,
      basePath,
      acConfigNewFrontendUrls
    ) ||
    getFrontendURL(
      'configurationv2',
      acConfigFrontendUrls,
      basePath,
      acConfigNewFrontendUrls
    ),
  integrations: getFrontendURL(
    'integrations',
    acConfigFrontendUrls,
    basePath,
    acConfigNewFrontendUrls
  ),
  housekeeping: getFrontendURL(
    'housekeeping',
    acConfigFrontendUrls,
    basePath,
    acConfigNewFrontendUrls
  ),
  housekeepingMobile: getFrontendURL(
    'housekeepingMobile',
    acConfigFrontendUrls,
    basePath,
    acConfigNewFrontendUrls
  ),
  reports: getFrontendURL(
    'reports',
    acConfigFrontendUrls,
    basePath,
    acConfigNewFrontendUrls
  ),
  reservations: getFrontendURL(
    'reservations',
    acConfigFrontendUrls,
    basePath,
    acConfigNewFrontendUrls
  ),
  changelog: getFrontendURL(
    'changelog',
    acConfigFrontendUrls,
    basePath,
    acConfigNewFrontendUrls
  ),
  rateManager: getFrontendURL(
    'rateManager',
    acConfigFrontendUrls,
    basePath,
    acConfigNewFrontendUrls
  ),
  accountsReceivable: getFrontendURL(
    'accountsReceivable',
    acConfigFrontendUrls,
    basePath,
    acConfigNewFrontendUrls
  ),
  meetingsAndEvents: getFrontendURL(
    'meetingsAndEvents',
    acConfigFrontendUrls,
    basePath,
    acConfigNewFrontendUrls
  ),
  travelAgentCommissions: getFrontendURL(
    'travelAgentCommissions',
    acConfigFrontendUrls,
    basePath,
    acConfigNewFrontendUrls,
    basePath ? 'taComissions' : undefined
  ),
  taskManagement: getFrontendURL(
    'taskManagement',
    acConfigFrontendUrls,
    basePath,
    acConfigNewFrontendUrls
  ),
  userDocumentation: getFrontendURL(
    'userDocumentation',
    acConfigFrontendUrls,
    basePath,
    acConfigNewFrontendUrls
  ),
  workflows: getFrontendURL(
    'workflows',
    acConfigFrontendUrls,
    basePath,
    acConfigNewFrontendUrls
  ),
  notifications: getFrontendURL(
    'notifications',
    acConfigFrontendUrls,
    basePath,
    acConfigNewFrontendUrls
  ),
  profiles: getFrontendURL(
    'profiles',
    acConfigFrontendUrls,
    basePath,
    acConfigNewFrontendUrls
  ),
  cashiering: getFrontendURL(
    'cashiering',
    acConfigFrontendUrls,
    basePath,
    acConfigNewFrontendUrls
  ),
  individualReservations: getFrontendURL(
    'individualReservations',
    acConfigFrontendUrls,
    basePath,
    acConfigNewFrontendUrls
  ),
  itinerary: getFrontendURL(
    'itinerary',
    acConfigFrontendUrls,
    basePath,
    acConfigNewFrontendUrls
  ),
  reservationsGroups: getFrontendURL(
    'reservationsGroups',
    acConfigFrontendUrls,
    basePath,
    acConfigNewFrontendUrls
  ),
  activityReservations: getFrontendURL(
    'activityReservations',
    acConfigFrontendUrls,
    basePath,
    acConfigNewFrontendUrls
  ),
  activityConfiguration: getFrontendURL(
    'activityConfiguration',
    acConfigFrontendUrls,
    basePath,
    acConfigNewFrontendUrls
  ),
  guestSelfService: getFrontendURL(
    'guestSelfService',
    acConfigFrontendUrls,
    basePath,
    acConfigNewFrontendUrls
  ),
  worldMap: getFrontendURL(
    'worldMap',
    acConfigFrontendUrls,
    basePath,
    acConfigNewFrontendUrls
  ),
  registrationCard: getFrontendURL(
    'registrationCard',
    acConfigFrontendUrls,
    basePath,
    acConfigNewFrontendUrls
  ),
  centralReservationOffice: getFrontendURL(
    'centralReservationOffice',
    acConfigFrontendUrls,
    basePath,
    acConfigNewFrontendUrls
  ),
  systemMaintenance: getFrontendURL(
    'systemMaintenance',
    acConfigFrontendUrls,
    basePath,
    acConfigNewFrontendUrls
  ),
  availability: getFrontendURL(
    'availability',
    acConfigFrontendUrls,
    basePath,
    acConfigNewFrontendUrls
  ),
  allocationCalendar: getFrontendURL(
    'allocationCalendar',
    acConfigFrontendUrls,
    basePath,
    acConfigNewFrontendUrls
  ),
  dataLake: getFrontendURL(
    'dataLake',
    acConfigFrontendUrls,
    basePath,
    acConfigNewFrontendUrls
  ),
  translations: getFrontendURL(
    'translations',
    acConfigFrontendUrls,
    basePath,
    acConfigNewFrontendUrls
  ),
  resourceManagement: getFrontendURL(
    'resourceManagement',
    acConfigFrontendUrls,
    basePath,
    acConfigNewFrontendUrls
  ),
});

export const config: Config = {
  ..._acConfig,
  apiUrl: _acConfig.apiUrl || '',
  frontendUrls: mapFrontendUrls(
    _acConfig.frontendUrls || {},
    _acConfig.containerFrontendUrl,
    _acConfig.newFrontendUrls || {}
  ),
  newFrontendUrls: mapFrontendUrls(
    getNewFrontendUrls(_acConfig.newFrontendUrls || {})
  ),
  identityOauthUrl: _acConfig.identityOauthUrl || '',
  regions: _acConfig.regions || [],
  environmentType: _acConfig.environmentType || 'local',
  externalUrls: getExternalUrls(_acConfig.externalUrls || {}),
};
